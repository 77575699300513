.cart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 5%;


     &__checkout button {
        width: 150px;
        height: 50px;
        background-color: rgb(19, 19, 19);
        color: white;
        border: none;
        border-radius: 8px;
        margin: 10px;
        cursor: pointer;
        margin-top: 50px;
      }

      &__items {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
      }

      &__subtotal {
        font-weight: 700;
        font-size: 25px;
        text-align: center;
      }

      @media screen and (max-width: 1200px) {
        width: 100%;
        padding: 5%;
      }
}

.cartItem {
    width: 100%;
    padding: 7%;
    height: 250px;
    display: flex;
    align-items: center;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    border-radius: 25px;
    margin: 30px;
    margin-bottom: 50px;

    &__img {
        width: 200px;
        margin-right: 20px;
      }
      
      &___description {
        width: 100%;
        font-size: 30px;
      }

    &__countHandler {
        margin-top: 10px;
    }
    &__countHandler input, button {
        width: 40px;
        text-align: center;
        font-weight: bolder;
        font-size: 18px;
      }

    &__countHandler button:hover {
        cursor: pointer;
      }

      @media screen and (max-width: 1200px) {
        &__img {
            width: 25%;
        }
      }
  }
  
  
