.nav {
    width: 100%;
    height: 80px;
    background-color: rgb(19, 19, 19);
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &__links {
        margin-right: 50px;
        display: flex;
        align-items: center;
    }

    &__links a {
        text-decoration: none;
        color: white;
        margin-left: 20px;
        font-size: 20px;
    }
}