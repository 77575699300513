.product {
    border-radius: 15px;
    width: 300px;
    height: 350px;
    margin: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__img {
        width: 100%;
        margin-bottom: auto;
    }

    &__title {
        margin-top: 25%;
    }

    &__description {
        text-align: center;
        margin-bottom: 15px;
    }

    &:hover {
        transition: 0.3s ease-in;
        cursor: pointer;
    }

    &__addToCartBtn {
        background-color: transparent;
        border: 2px solid rgb(19, 19, 19);
        min-width: 100px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        border-radius: 15px;
      }
      
      &__addToCartBtn:hover {
        background-color: rgb(19, 19, 19);
        color: white;
        cursor: pointer;
      }
  }