* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.home {

    &__title {
        margin-top: 100px;
        text-align: center;
    }

    &__title h1 {
        font-size: 300%;
    }

    &__productContainer {
        max-width: 1400px;
        margin: 0 auto;
    }

    &__products {
        max-width: 1200px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-content: center;
    }

    @media screen and (max-width: 768px) {
        &__products {
            display: flex;
            flex-direction: column;
        }
    }

    @media screen and (max-width: 1200px) {
        &__products {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}

.App {
    width: 100%;
    height: auto;
    min-height: 100vh;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}